import '@fontsource/playfair-display/500.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/700.css'

import * as React from 'react'
import Wrapper from './src/components/wrapper'

export const wrapRootElement = ({ element }) => {
  return <Wrapper>{element}</Wrapper>
}
