import * as React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@mui/material/styles'
import ColorModeContext from './color_mode_context'
import CssBaseline from './css_baseline'
import {
  colorModeKey,
  initialColorModeCssProp,
  themeColor,
  dynamicColors,
  theme,
} from '../constants'
import type { PaletteMode } from '@mui/material'

export const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const [colorModeState, setColorModeState] = React.useState<PaletteMode>()

  React.useEffect(() => {
    const root = window.document.documentElement
    const initialColorMode = root.style.getPropertyValue(initialColorModeCssProp)

    if (initialColorMode) {
      setColorModeState(initialColorMode as PaletteMode)
    }
  }, [])

  const contextValue = React.useMemo(() => {
    const setColorMode = (newMode: PaletteMode) => {
      localStorage.setItem(colorModeKey, newMode)

      const root = window.document.documentElement

      Object.entries(dynamicColors).forEach(([name, colorByTheme]) => {
        root.style.setProperty(`--color-${name}`, colorByTheme[newMode])
      })

      setColorModeState(newMode)
    }

    return {
      setColorMode,
      colorMode: colorModeState,
    }
  }, [colorModeState, setColorModeState])

  const contextTheme = theme[colorModeState || 'light']

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name='theme-color'
          media='(prefers-color-scheme: light)'
          content={themeColor.light}
        />
        <meta
          name='theme-color'
          media='(prefers-color-scheme: dark)'
          content={themeColor.dark}
        />
      </Helmet>
      <CssBaseline />
      <ColorModeContext.Provider value={contextValue}>
        <ThemeProvider theme={contextTheme}>
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </React.Fragment>
  )
}

export default Wrapper
