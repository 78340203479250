import * as React from 'react'
import type { FirebaseOptions } from 'firebase/app'
import FirebaseContext, { createNullContext } from './context'

const config: FirebaseOptions = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
}

const Wrapper: React.FC = ({ children }) => {
  const [firebase, setFirebase] = React.useState(createNullContext())

  React.useEffect(() => {
    Promise.all([
      import('firebase/app'),
      import('firebase/analytics'),
    ]).then(([firebaseModule, analyticsModule]) => {
      const app = firebaseModule.initializeApp(config)
      const analytics = analyticsModule.getAnalytics(app)

      setFirebase({
        app,
        analytics,
        logEvent: analyticsModule.logEvent,
        setUserId: analyticsModule.setUserId,
      })
    })
  }, [])

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  )
}

export default Wrapper
