import * as React from 'react'
import type { FirebaseApp } from 'firebase/app'
import type { Analytics, logEvent, setUserId } from 'firebase/analytics'

type MaybeNullFirebaseApp = {
  app: FirebaseApp | null
  analytics: Analytics | null
  logEvent: typeof logEvent
  setUserId: typeof setUserId
}

export const createNullContext = (): MaybeNullFirebaseApp => ({
  app: null,
  analytics: null,
  logEvent: () => null,
  setUserId: () => null,
})

export const FirebaseContext = React.createContext(createNullContext())

export default FirebaseContext
