import { createTheme, responsiveFontSizes } from '@mui/material'
import { cryptoColorMap } from './cryptos'
import type { PaletteMode, ThemeOptions } from '@mui/material'
import type { Shadows } from '@mui/material/styles/shadows'

export const colorModeKey = 'color-mode'
export const defaultColorMode = 'light'
export const initialColorModeCssProp = '--initial-color-mode'

export const themeColor = {
  'light': '#fcfcfc',
  'dark': '#000',
}

export const dynamicColors = {
  text: {
    'light': '#000',
    'dark': '#fcfcfc',
  },
  background: {
    'light': '#fcfcfc',
    'dark': '#000000',
  },
  shadowColor: {
    'light': '0 0% 0%',
    'dark': '176 59% 72%',
  },
  mainTitleGradient: {
    'light': '-webkit-linear-gradient(120deg,hsl(36 100% 68%) 0%,hsl(346 74% 50%) 15%,hsl(248 49% 49%) 45%)',
    'dark': '-webkit-linear-gradient(120deg,hsl(36 100% 68%) 0%,hsl(346 74% 50%) 15%,hsl(248 49% 49%) 45%)',
  },
}

// eslint-disable-next-line max-len
const disabledGradient = '-webkit-linear-gradient(120deg,hsl(0deg 0% 44%) 10%,hsl(0deg 0% 34%) 40%,hsl(0deg 0% 24%) 100%)'

const lowShadow = [
  '0.7px 0.7px 0.7px hsl(var(--color-shadowColor) / 0.69)',
  '2.6px 2.6px 2.8px -5px hsl(var(--color-shadowColor) / 0.23)',
].join(',')

const midShadow = [
  '0.7px 0.7px 0.7px hsl(var(--color-shadowColor) / 0.72)',
  '1.1px 1.1px 1.2px -1.7px hsl(var(--color-shadowColor) / 0.54)',
  '4.4px 4.4px 4.7px -3.3px hsl(var(--color-shadowColor) / 0.36)',
  '13.2px 13.2px 14px -5px hsl(var(--color-shadowColor) / 0.18)',
].join(',')

const highShadow = [
  '0.5px 0.5px 0.5px hsl(var(--color-shadowColor) / 1)',
  '0.7px 0.7px 0.7px -0.7px hsl(var(--color-shadowColor) / 0.89)',
  '2.2px 2.3px 2.4px -1.4px hsl(var(--color-shadowColor) / 0.76)',
  '6.2px 6.5px 6.7px -2.1px hsl(var(--color-shadowColor) / 0.63)',
  '14.1px 14.7px 15.3px -2.9px hsl(var(--color-shadowColor) / 0.51)',
  '27.1px 28.3px 29.4px -3.6px hsl(var(--color-shadowColor) / 0.38)',
  '46.5px 48.5px 50.4px -4.3px hsl(var(--color-shadowColor) / 0.25)',
  '73.6px 76.8px 79.8px -5px hsl(var(--color-shadowColor) / 0.13)',
].join(',')

const shadows = (mode: PaletteMode) => [
  'none',
  ...Array(5).fill(lowShadow),
  ...Array(5).fill(midShadow),
  ...Array(10).fill(mode === 'light' ? highShadow : midShadow),
  ...Array(4).fill(highShadow),
] as Shadows

const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
  const palette = {
    mode,
    action: {
      hoverOpacity: 0.16 * (mode === 'light' ? 1 : 2),
      selectedOpacity: 0.26 * (mode === 'light' ? 1 : 2),
      disabledOpacity: 0.8,
      focusOpacity: 0.48,
      activatedOpacity: 0.48,
    },
    primary: {
      main: '#004039',
      dark: '#092B28',
      light: '#8FE2DD',
    },
    text: {
      primary: dynamicColors.text[mode],
    },
    background: {
      default: 'var(--color-background)',
      paper: 'var(--color-background)',
    },
    divider: mode === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.52)',
    ...cryptoColorMap,
  }

  const modeShadows = shadows(mode)

  return {
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            boxShadow: modeShadows[8],
            color: dynamicColors.text.dark,
            ':hover': {
              boxShadow: modeShadows[8],
            },
            '&.Mui-disabled': {
              background: disabledGradient,
              color: dynamicColors.text.dark,
            },
          },
          outlinedPrimary: {
            color: mode === 'light' ? palette.primary.dark : dynamicColors.text.dark,
            borderColor: palette.primary.main,
          },
          textPrimary: {
            color: mode === 'light' ? palette.primary.main : palette.primary.light,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              color: mode === 'light' ? palette.primary.dark : dynamicColors.text.dark,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          outlinedPrimary: {
            color: mode === 'light' ? palette.primary.dark : dynamicColors.text.dark,
            borderColor: palette.primary.main,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: mode === 'light' ? palette.primary.dark : palette.primary.light,
            textDecorationColor: mode === 'light' ? palette.primary.dark : palette.primary.light,
          },
        },
      },
    },
    palette: palette,
    shadows: modeShadows,
    typography: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      button: {
        fontWeight: 400,
        textTransform: 'none',
      },
    },
  }
}

export const theme = {
  'light': responsiveFontSizes(createTheme(getDesignTokens('light'))),
  'dark': responsiveFontSizes(createTheme(getDesignTokens('dark'))),
}
