import * as React from 'react'
import type { PaletteMode } from '@mui/material'

type ColorModeContextType = {
  setColorMode: (newValue: PaletteMode) => void
  colorMode: PaletteMode | undefined
}

const createInitialContext = (): ColorModeContextType => ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setColorMode: (newValue: PaletteMode) => {},
  colorMode: undefined,
})

export const ColorModeContext = React.createContext(createInitialContext())

export default ColorModeContext
