import * as React from 'react'
import GlobalStyles, { GlobalStylesProps } from '@mui/material/GlobalStyles'
import { alpha } from '@mui/material'
import { theme } from '../constants'

type StyleProps = GlobalStylesProps['styles']

const typography = theme.light.typography
const primaryColor = theme.light.palette.primary.main

const htmlStyles: StyleProps = ({
  // Antialiasing.
  WebkitFontSmoothing: 'antialiased',
  // Antialiasing.
  MozOsxFontSmoothing: 'grayscale',
  // Change from `box-sizing: content-box` so that `width`
  // is not affected by `padding` or `border`.
  boxSizing: 'border-box',
  // Fix font resize problem in iOS
  WebkitTextSizeAdjust: '100%',
})

const bodyStyles: StyleProps = ({
  // Set colors based on CSS variables.
  color: 'var(--color-text)',
  backgroundColor: 'var(--color-background)',
  // Add support for document.body.requestFullScreen().
  // Other elements, if background transparent, are not supported.
  '&::backdrop': {
    backgroundColor: 'var(--color-background)',
  },
  // Save printer ink.
  '@media print': {
    backgroundColor: 'white',
  },
  // Add a smooth color transition between light and dark modes.
  transition: 'background-color .3s ease',
  // Typography
  fontFamily: typography.body1.fontFamily,
  fontWeight: typography.body1.fontWeight,
  fontSize: typography.body1.fontSize,
  lineHeight: typography.body1.lineHeight,
  letterSpacing: typography.body1.letterSpacing,
  // Remove the margin in all browsers.
  margin: 0,
})

const baseStyles: StyleProps = ({
  html: htmlStyles,
  body: bodyStyles,
  '*, *::before, *::after': {
    boxSizing: 'inherit',
  },
  'strong, b': {
    fontWeight: typography.fontWeightBold,
  },
  'input': {
    ':-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active': {
      WebkitTextFillColor: 'var(--color-text) !important',
      WebkitBoxShadow: `0 0 0 30px ${alpha(primaryColor, 0.12)} inset !important`,
      backgroundColor: `${alpha(primaryColor, 0.12)} !important`,
      backgroundClip: 'text !important',
    },
    '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '[type=number]': {
      MozAppearance: 'textfield',
    },
  },
})

const CssBaseline = () => {
  return <GlobalStyles styles={baseStyles} />
}

export default CssBaseline
