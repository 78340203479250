import { PaletteColorOptions } from '@mui/material'
import { darken, lighten } from '@mui/system'
import type { Theme } from '@mui/material'

export type CryptoColor = (
  'bitcoin' | 'ethereum' | 'cardano' | 'polkadot' | 'usdt' | 'usdc'
)

export const getColorShade = (theme: Theme, color: CryptoColor) => {
  return theme.palette.mode === 'light'
    ? lighten(theme.palette[color].main, 0.62)
    : darken(theme.palette[color].main, 0.5)
}

export const getContrastText = (theme: Theme, color: CryptoColor) => {
  return theme.palette.getContrastText(theme.palette[color].main)
}

export const cryptoColorMap: { [key in CryptoColor]: PaletteColorOptions } = {
  bitcoin: {
    main: '#f7931a',
  },
  ethereum: {
    main: '#323a63',
  },
  cardano: {
    main: '#19384f',
  },
  polkadot: {
    main: '#e6007a',
  },
  usdt: {
    main: '#0a7753',
  },
  usdc: {
    main: '#3e88d5',
  },
}

declare module '@mui/material/styles' {
  interface Palette {
    bitcoin: Palette['primary']
    ethereum: Palette['primary']
    cardano: Palette['primary']
    polkadot: Palette['primary']
    usdt: Palette['primary']
    usdc: Palette['primary']
  }

  interface PaletteOptions {
    bitcoin?: PaletteOptions['primary']
    ethereum?: PaletteOptions['primary']
    cardano?: PaletteOptions['primary']
    polkadot?: PaletteOptions['primary']
    usdt?: PaletteOptions['primary']
    usdc?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    bitcoin: true
    ethereum: true
    cardano: true
    polkadot: true
    usdt: true
    usdc: true
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    bitcoin: true
    ethereum: true
    cardano: true
    polkadot: true
    usdt: true
    usdc: true
  }
}
